import React from 'react';
import PropTypes from 'prop-types';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Pagination
} from '@mui/material';

import { CiWarning } from "react-icons/ci";

function Tabla({ columns, data, totalRows, page, setPage }) {
    let rowsPerPage = 50;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const startIndex = (page - 1) * rowsPerPage + 1;
    const currentPage = Math.ceil(startIndex / rowsPerPage);

    return (
        <Paper sx={{ borderRadius: 5 }}>
            <Pagination sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }} count={totalPages} page={currentPage} onChange={handleChangePage} />
            <TableContainer sx={{ height: '60vh' }}>
                <Table stickyHeader aria-label="sticky table">
                    {/* <Table> */}
                    {data.length === 0 ? (
                        <>
                        <TableHead>
                            <TableRow>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={columns.length} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', alignItems: 'center', minHeight: '28pc'}}>
                                    <CiWarning size={130} />
                                    No hay datos para mostrar
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </>
                    ) : (
                        <>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.field} sx={{ minWidth: '160px' }} >
                                        {column.headerName}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    {columns.map((column) => (
                                        <TableCell key={column.field}>
                                            {row[column.field]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                            }
                        </TableBody>
                        </>
                    )}
                </Table>
            </TableContainer>
        </Paper>
    );
}

Tabla.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        headerName: PropTypes.string.isRequired,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalRows: PropTypes.number.isRequired,
    page: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired
};

export default Tabla;
