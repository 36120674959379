import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, MenuItem, Select, TextField } from '@mui/material';

//components
import MarginContent from "../../components/MarginContent";
import Menu from '../../components/Menu';
import Tabla from '../../components/Tabla';
import AddUserImport from './comoponents/addUserImport';
import { dataUsers } from './data/tabla';
import CreateUser from './comoponents/createUser';
import PutUser from './comoponents/updateUser';

//icons
// import { FaWhatsapp } from "react-icons/fa";

function Users() {

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [openDialog3, setOpenDialog3] = useState(false);

    const [searchType, setSearchType] = useState('codigo'); // Estado para el tipo de búsqueda
    const [dataUser, setDataUser] = useState('');
    const [text, setText] = useState(''); // Estado para el valor del input
    
    const [page, setPage] = useState(1);
    const [idUser, setIdUser] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const [dataView, setDataView] = useState({ columns: [], data: [] });
    const { columns, data } = dataView;

    const handleOpenDialog = () => { setOpenDialog(true); };
    const handleCloseDialog = () => { setOpenDialog(false); };
    const handleOpenDialog2 = () => { setOpenDialog2(true); };
    const handleCloseDialog2 = () => { setOpenDialog2(false); };
    const handleOpenDialog3 = () => { setOpenDialog3(true); };
    const handleCloseDialog3 = () => { setOpenDialog3(false); };
    const handleChange = (event) => { setText(event.target.value); setPage(1) };
    const handleRefresh = () => { setRefresh(prevRefresh => prevRefresh + 1); };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setText('');
    };

    useEffect(() => {
        const getTableData = async () => {
            const tableData = await dataUsers(searchType, text, page, setTotalRows, (id, user) => {
                setIdUser(id);
                setDataUser(user);
                handleOpenDialog3();
            });
            if (tableData) {
                setDataView(tableData);
            }
        };
        getTableData();
    }, [refresh, totalRows, page, searchType, text]);

    return (
        <>
            <MarginContent>
                <Menu />
                <Card sx={{ p: 2, borderRadius: 5, border: 'none', marginTop: 4, marginBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Acciones

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                        <Select
                            value={searchType}
                            onChange={handleSearchTypeChange}
                            sx={{
                                height: '36px',
                                borderRadius: 3,
                                minWidth: '140px',
                                '& .MuiSelect-root': {
                                    paddingTop: '9px',
                                },
                                '& .MuiInputBase-root': {
                                    borderRadius: 3,
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '15px',
                                    top: '-7px'
                                },
                            }}
                        >
                            <MenuItem value="codigo">Buscar código punto</MenuItem>
                            <MenuItem value="telefono">Buscar teléfono</MenuItem>
                        </Select>
                        <TextField
                            label={searchType === 'codigo' ? 'Escribe el codigo' : 'Escribe el teléfono'}
                            variant="outlined"
                            name="usuario"
                            value={text}
                            disabled={loading2}
                            onChange={handleChange}
                            margin="normal"
                            sx={{
                                margin: 0,
                                '& .MuiInputBase-root': {
                                    height: '36px',
                                    borderRadius: 3,
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '15px',
                                    top: '-7px'
                                },
                                '& .MuiInputBase-input': {
                                    height: '35px',
                                    width: '140px',
                                },
                            }}
                        />
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            disabled={loading || loading2}
                            onClick={handleOpenDialog}
                            sx={{ textTransform: 'initial', borderRadius: 3 }}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Importar usuarios'}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            disabled={loading || loading2}
                            onClick={handleOpenDialog2}
                            sx={{ textTransform: 'initial', backgroundColor: 'var(--color-principal)', borderRadius: 3 }}
                        >
                            {loading2 ? <CircularProgress size={24} color="inherit" /> : 'Crear usuario'}
                        </Button>
                    </Box>
                </Card>

                <Tabla columns={columns} data={data} totalRows={totalRows} page={page} setPage={setPage} />
                <AddUserImport handleRefresh={handleRefresh} openDialog={openDialog} handleCloseDialog={handleCloseDialog} setLoading={setLoading} />
                <CreateUser handleRefresh={handleRefresh} openDialog={openDialog2} handleCloseDialog={handleCloseDialog2} setLoading={setLoading2} />
                <PutUser dataUser={dataUser} handleRefresh={handleRefresh} idUser={idUser} openDialog={openDialog3} handleCloseDialog={handleCloseDialog3} />
            </MarginContent >
        </>
    );
}

export default Users;