import Swal from "sweetalert2";

export const Checkgeneral = (text) => {
    Swal.fire({
        title: `${text}`,
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        width: 560,
        timer: 3800,
        stopKeydownPropagation: true,
        showClass: {
            popup: 'animate__animated animate__bounceInRight'
        },
        hideClass: {
            popup: 'animate__animated animate__bounceOutRight'
        },
        customClass: {
            popup: 'Content_Swall',
        }
    });
}

// -------------------------------------------------------------------------- 

export const Infogeneral = (text) => {
    Swal.fire({
        title: `${text}`,
        icon: 'info',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        width: 560,
        timer: 3800,
        stopKeydownPropagation: true,
        showClass: {
            popup: 'animate__animated animate__bounceInRight'
        },
        hideClass: {
            popup: 'animate__animated animate__bounceOutRight'
        },
        customClass: {
            popup: 'Content_Swall_Info',
        }
    });
}

// -------------------------------------------------------------------------- 

export const ErrorGeneral = (text) => {
    Swal.fire({
        title: `${text}`,
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        width: 560,
        timer: 5800,
        stopKeydownPropagation: true,
        showClass: {
            popup: 'animate__animated animate__bounceInRight'
        },
        hideClass: {
            popup: 'animate__animated animate__bounceOutRight'
        },
        customClass: {
            popup: 'Content_Swall_error',
        }
    });
}