import React from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoute } from './context/protect';

const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }
        if (route.route) {
            // Verificar si la ruta tiene roles requeridos
            if (route.requiredRoles) {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={
                            <ProtectedRoute requiredRoles={route.requiredRoles}>
                                {route.component}
                            </ProtectedRoute>
                        }
                        key={route.key}
                    />
                );
            } else {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={route.component}
                        key={route.key}
                    />
                );
            }
        }
        return null;
    });

export default getRoutes;
