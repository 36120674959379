import Axios from "../../../config/axios";
import { ErrorGeneral } from "../../Alerts";
import { URL_ACTIVE_WH } from "../../../constanst/link";
import { isAuthenticated } from "../../../context/verificationUser";

export const wh = async () => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    try {
        const response = await Axios.get(URL_ACTIVE_WH);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        ErrorGeneral('Error al cambiar los estados de WhatsApp')
        console.error('Error al cambiar los estados de wh:', error);
    }
};
