import { Infogeneral } from "../../../components/Alerts";
import Axios from "../../../config/axios";
import { URL_COMMISSION_TEMPORAL } from "../../../constanst/link";
import { isAuthenticated } from "../../../context/verificationUser";

export const temporal = async (text, page, setTotalRows) => {
    let pageSize = 50

    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    try {
        const response = await Axios.get(`${URL_COMMISSION_TEMPORAL}?pageSize=${pageSize}&CodigoPunto=${text}&page=${page}`);

        if (response.status === 200) {
            setTotalRows(response.data.total);
            return response.data.Founded;
        }
    } catch (error) {
        console.error('Error al cargar:', error);
        Infogeneral('Debes cagar una comision para visualizar el contenido');
    }
};