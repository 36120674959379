import Axios from "../../../config/axios";
import { isAuthenticated } from "../../../context/verificationUser";
import { URL_COMMISSION_TEMPORAL_PROCESAR } from "../../../constanst/link";
import { Checkgeneral, ErrorGeneral } from "../../../components/Alerts";

export const process = async () => {

    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    try {
        const response = await Axios.get(URL_COMMISSION_TEMPORAL_PROCESAR);

        if (response.status === 200) {
            Checkgeneral('Estamos procesando esta comision, tardará unos minutos');
            setTimeout(function () {
                Checkgeneral('Los botones se habilitaran cuando termine el proceso');
            }, 3000);
        }
    } catch (error) {
        console.error('Error al procesar:', error);
        ErrorGeneral('Vaya... tubimos un problema procesar');
    }
};