import { Checkgeneral, ErrorGeneral } from "../../../../components/Alerts";
import Axios from "../../../../config/axios";
import { URL_COMMISSION_WH } from "../../../../constanst/link";
import { isAuthenticated } from "../../../../context/verificationUser";

export const WH = async (mes, anio, code, setLoading) => {
    setLoading(true);
    const verify = isAuthenticated();
    
    if (!verify || !mes || !anio || !code) {
        console.error("User parameters are missing");
        setLoading(false);
        return;
    }

    let queryParams = {
        mes: mes,
        anio: anio,
        Codigo_Punto: (code || "").toString()
    };

    try {
        const response = await Axios.post(URL_COMMISSION_WH, queryParams);

        if (response.status === 200) {
            Checkgeneral('Espera unos segundos mientras llega el mensaje');
        } else {
            console.error('Error al cargar:', response);
            ErrorGeneral('Error al cargar en la respuesta del mensaje');
        }
    } catch (error) {
        console.error('Error al cargar:', error);
        ErrorGeneral(error.response?.data || 'Error al cargar al enviar mensaje');
    } finally {
        setLoading(false);
    }
}
