import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress } from '@mui/material';

//components
import MarginContent from "../../components/MarginContent";
import Tabla from '../../components/Tabla';
import Menu from '../../components/Menu';
import { dataRates } from './data/tablas';
import { ErrorGeneral } from '../../components/Alerts';
import { exportTarifa } from './data/triggers/getExport';
import AddRate from './components/addRates';
import PutRate from './components/putRates';

//icons
// import { FaWhatsapp } from "react-icons/fa";

function Rate() {

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    // const [loading3, setLoading3] = useState(false);


    const [openDialog, setOpenDialog] = useState(false);
    // const [openDialog2, setOpenDialog2] = useState(false);
    const [openDialog3, setOpenDialog3] = useState(false);

    const [idRate, setIdRate] = useState(0);
    const [valueRates, setValueRates] = useState(0);

    const [refresh, setRefresh] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);

    const [dataView, setDataView] = useState({ columns: [], data: [] });
    const { columns, data } = dataView;

    const handleOpenDialog = () => { setOpenDialog(true); };
    const handleCloseDialog = () => { setOpenDialog(false); };
    const handleOpenDialog3 = () => { setOpenDialog3(true); };
    const handleCloseDialog3 = () => { setOpenDialog3(false); };

    const handleRefresh = () => { setRefresh(prevRefresh => prevRefresh + 1); };

    const handleSubmitExport = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await exportTarifa(setLoading);
        } catch (error) {
            console.error('Error en handleSubmit:', error);
            setLoading(false);
            ErrorGeneral('Vaya... tuvimos un problema en el botom');
        }
    };

    useEffect(() => {
        const getTableData = async () => {
            const tableData = await dataRates(page, setTotalRows, (id, valueRate) => {
                setIdRate(id);
                setValueRates(valueRate);
                handleOpenDialog3();
            });
            if (tableData) {
                setDataView(tableData);
            }
        };
        getTableData();
    }, [refresh, totalRows, page]);

    return (
        <>
            <MarginContent>
                <Menu />
                <Card sx={{ p: 2, borderRadius: 5, border: 'none', marginTop: 4, marginBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Acciones

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            disabled={loading || loading2}
                            onClick={handleSubmitExport}
                            sx={{ textTransform: 'initial', borderRadius: 3 }}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Exportar tarifa'}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            disabled={loading || loading2}
                            onClick={handleOpenDialog}
                            sx={{ textTransform: 'initial', backgroundColor: 'var(--color-secundario)', borderRadius: 3 }}
                        >
                            {loading2 ? <CircularProgress size={24} color="inherit" /> : 'Importar datos'}
                        </Button>
                    </Box>
                </Card>

                <Tabla columns={columns} data={data} totalRows={totalRows} page={page} setPage={setPage} />
                <AddRate handleRefresh={handleRefresh} openDialog={openDialog} handleCloseDialog={handleCloseDialog} setLoading={setLoading2} />
                <PutRate handleRefresh={handleRefresh} idRate={idRate} valueRates={valueRates} openDialog={openDialog3} handleCloseDialog={handleCloseDialog3} />
            </MarginContent>
        </>
    );
}

export default Rate;