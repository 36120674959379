import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';

import { sendCode } from '../data/triggers/postSendCode';
import { verificaction } from '../data/triggers/postVerificationCode';

const LoginWH = () => {
    const [step, setStep] = useState(1); // Controla el paso del flujo de login
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');

    const [loading, setLoading] = useState(false);

    const handleUsernameSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            await sendCode(username, setStep, setError, setLoading);
        } catch (error) {
            console.error('Error al enviar codigo:', error);
        }
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            await verificaction(username, code, setError, setLoading);
        } catch (error) {
            console.error('Error al enviar codigo:', error);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Iniciar sesión
            </Typography>

            {step === 1 && (
                <Box component="form" onSubmit={handleUsernameSubmit} sx={{ width: '100%', maxWidth: '400px' }}>
                    <TextField
                        label="Usuario"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        sx={{ marginTop: 2, textTransform: 'initial', backgroundColor: 'var(--color-principal)' }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar código'}
                    </Button>
                    {error && (
                        <Typography variant="body2" sx={{ color: 'red', marginTop: 2 }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            )}

            {step === 2 && (
                <Box component="form" onSubmit={handleCodeSubmit} sx={{ width: '100%', maxWidth: '400px' }}>
                    <Typography variant="subtitle1" gutterBottom textAlign="center">
                        Se ha enviado un código de verificación a tu número de WhatsApp.
                    </Typography>
                    <TextField
                        label="Código de verificación"
                        variant="outlined"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        sx={{ marginTop: 2, textTransform: 'initial', backgroundColor: 'var(--color-principal)' }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Verificar código'}
                    </Button>
                    {error && (
                        <Typography variant="body2" sx={{ color: 'red', marginTop: 2 }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            )}

        </Box>
    );
};

export default LoginWH;
