import React, { useState } from 'react';
import { Card, Box, Tabs, Tab } from '@mui/material';

// components
import LoginWH from './components/loginWh';
import LoginUser from './components/loginUser';

// imagenes
import img from '../../assets/img/undraw_moonlight_-5-ksn.svg'
import logo from "../../assets/img/logo.png";
import PageFooter from '../../components/footer';

function Authentication() {

    const TabPanel = ({ children, value, index }) => (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={3} sx={{color: 'var(--color-principal)' }}>{children}</Box>}
        </div>
    );

    const [tabValue, setTabValue] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100%',
                }}
            >
                <Card
                    sx={{
                        padding: 5,
                        borderRadius: 9,
                        boxShadow: '0px 0px 100px 40px rgba(0, 0, 0, 0.2)',
                        width: {
                            xs: '80%', // para pantallas móviles
                            md: '66%',
                            ml: '92%',
                        },
                        height: {
                            xs: '55vh',
                            sm: 'auto',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                    // sm: 'block',
                                }
                                ,color: 'var(--color-principal)' 
                            }}
                        >
                            <img src={logo} alt='logo' style={{ width: '140px' }} />
                            <p>Inicia sesión con cualquiera de estas dos opciones</p>
                            <img src={img} alt='image-stree' style={{ width: '340px' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '300px',
                                zIndex: 2,
                            }}
                        >
                            <Tabs value={tabValue} onChange={handleChangeTab} centered >
                                <Tab label="usuario" sx={{ textTransform: 'capitalize'}}/>
                                <Tab label="WhatsApp" sx={{ textTransform: 'capitalize'}}/>
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <LoginUser />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <LoginWH />
                            </TabPanel>
                        </Box>
                    </Box>
                </Card>
            </Box>
            <PageFooter />
        </>
    );
}

export default Authentication;