import Cookies from "js-cookie";
import AES from 'crypto-js/aes';
import Axios from "../../../../config/axios";
import { URL_LOGIN_VERIFICATION_CODE, URL_SECRET } from "../../../../constanst/link";

export const verificaction = async (username, code, setError, setLoading) => {

    try {
        let data = { usuario: username, code: code }
        const response = await Axios.post(URL_LOGIN_VERIFICATION_CODE, data);

        if (response.status === 200) {
            const userObjectCookie = response.data.user
            const tokenObjectCookie = response.data.token

            const encryptedUserObject = AES.encrypt(JSON.stringify(userObjectCookie), URL_SECRET).toString();
            const encryptedTokenObject = AES.encrypt(tokenObjectCookie, URL_SECRET).toString();

            Cookies.set('object', encryptedUserObject, { secure: true });
            Cookies.set('payload', encryptedTokenObject, { secure: true });

            window.location.href = '/dashboard';
        } else {
            setError('Código de verificación incorrecto. Por favor, inténtalo de nuevo.');
        }
    } catch (error) {
        console.error('Error al verificar el código:', error);
        setError('Error al verificar el código. Por favor, inténtalo más tarde.');
    } finally {
        setLoading(false);
    }
};