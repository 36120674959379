import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

//components
import ModalBig from '../../../components/Modal/modalBig';
import { ErrorGeneral, Infogeneral } from '../../../components/Alerts';
import { put } from '../data/triggers/putRate';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    p: 4,
};

function PutRate({ handleRefresh, idRate, valueRates, openDialog, handleCloseDialog }) {
    const [valorTarifa, setValorTarifa] = useState('');

    const deleteValues = async (e) => {
        setValorTarifa('');
    }

    const handleSubmitCreate = async (e) => {
        e.preventDefault();

        if (!valorTarifa) {
            Infogeneral('Debe rellenar al menos uno de los campos.');
            handleCloseDialog();
            handleRefresh();
            return;
        }

        handleCloseDialog();
        deleteValues();

        try {
            const rates = {
                Valor_Tarifa: valorTarifa,
            };
            await put(rates, idRate, handleRefresh);

        } catch (error) {
            console.error('Error en handleSubmit:', error);
            ErrorGeneral('Vaya... tuvimos un problema en el botón');
        }
    };

    return (
        <ModalBig openDialog={openDialog} handleCloseDialog={handleCloseDialog}>
            <Box sx={style} component="form" onSubmit={handleSubmitCreate}>
                <Typography sx={{ fontSize: 20 }}>Cambia el valor de la tarifa</Typography>
                <TextField
                    label="Valor Tarifa"
                    type='number'
                    placeholder={valueRates}
                    value={valorTarifa}
                    onChange={(e) => setValorTarifa(e.target.value)}
                    fullWidth
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: 'initial', backgroundColor: 'var(--color-principal)', borderRadius: 3 }}
                    >
                        Actualizar tarifa
                    </Button>
                    <Button
                        onClick={() => { handleCloseDialog(); deleteValues(); }}
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: 'initial', backgroundColor: 'red', borderRadius: 3 }}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Box>
        </ModalBig>
    );
}

PutRate.propTypes = {
    idRate: PropTypes.number,
    openDialog: PropTypes.bool.isRequired,
    valueRates: PropTypes.number.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default PutRate;