import { Button, Tooltip } from "@mui/material";
import { users } from "./getUsers";

export const dataUsers = async (searchType, text,page, setTotalRows, accions) => {
    try {
        const response = await users(searchType, text, page, setTotalRows);

        const data = response.map(user => ({
            active: user.active !== undefined ? user.active === 1 ? 'Activo' : 'Inactivo' : 'No tiene registro',
            email: user.email || 'No tiene registro',
            fullname: user.fullname || 'No tiene registro',
            groupid: user.groupid || 'No tiene registro',
            id: user.id !== undefined ? user.id : 'No tiene registro',
            password: user.password || 'No tiene registro',
            username: user.username || 'No tiene registro',
            number: user.numero_celular || 'No tiene registro',
            wh: user.wh !== undefined ? user.wh.toString() : 'No tiene registro',
            accion: <Tooltip title="Edita este usuario">
                <Button type="button" variant="contained" sx={{ textTransform: 'initial', borderRadius: 3 }}
                    onClick={(e) => {
                        e.preventDefault(); // Previene la navegación del enlace
                        accions(user.id, user); 
                    }}
                >Editar</Button></Tooltip>,
        }));

        const columns = [
            { field: 'id', headerName: 'ID' },
            { field: 'username', headerName: 'Codigo punto' },
            { field: 'accion', headerName: 'Acción' },
            { field: 'active', headerName: 'Activo' },
            { field: 'number', headerName: 'Número de teléfono' },
            { field: 'email', headerName: 'Correo Electrónico' },
            { field: 'fullname', headerName: 'Nombre Completo' },
            { field: 'groupid', headerName: 'ID del Grupo' },
            { field: 'password', headerName: 'Contraseña' },
            { field: 'wh', headerName: 'WH' }
        ];


        return { columns, data };
    } catch (error) {
        console.error('Error al obtener los datos tableUsers: ', error);
    }
}