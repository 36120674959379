import React from 'react';
import { Navigate } from 'react-router-dom';
import { hasRole } from './verificationUser';

export const ProtectedRoute = ({ children, requiredRoles }) => {
    if (!hasRole(requiredRoles)) {
        return <Navigate to="/signIn" />;
    }

    return children;
};