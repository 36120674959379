export const getMonthName = (monthNumber) => {
    const monthNames = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return monthNames[monthNumber - 1];
};

export const getMonth = () => {
    const fechaActual = new Date();
    const mes = fechaActual.getMonth() + 1; // getMonth() devuelve 0-11, sumamos 1 para obtener 1-12
    const anio = fechaActual.getFullYear(); // getFullYear() devuelve el año completo
    return { mes, anio };
}
