import Cookies from "js-cookie";
import AES from 'crypto-js/aes';
import Axios from "../../../../config/axios";
import { URL_LOGIN_USER, URL_SECRET } from "../../../../constanst/link";

export const User = async (formData, setError, setLoading) => {
  try {
    const response = await Axios.post(URL_LOGIN_USER, formData);

    const userObjectCookie = response.data.user
    const tokenObjectCookie = response.data.token

    const encryptedUserObject = AES.encrypt(JSON.stringify(userObjectCookie), URL_SECRET).toString();
    const encryptedTokenObject = AES.encrypt(tokenObjectCookie, URL_SECRET).toString();

    Cookies.set('object', encryptedUserObject, { secure: true });
    Cookies.set('payload', encryptedTokenObject, { secure: true });

    window.location.href = '/dashboard';

  } catch (error) {
    console.error('Error al iniciar sesión:', error.response);
    setError(error.response?.data || 'Credenciales incorrectas. Inténtalo de nuevo.');
  } finally {
    setLoading(false);
  }
};
