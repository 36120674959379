import { Button, CircularProgress, Tooltip } from "@mui/material";
import { facts } from "./getFact";
import { getMonthName } from "../../../utils/getMonth/index.";
import { FaWhatsapp } from "react-icons/fa6";
import { userVerify } from "../../../utils/user/getUser";

export const dataFactAdmin = async (mes, anio, text, page, setTotalRows, accions) => {
    try {
        const response = await facts(mes, anio, text, page, setTotalRows);

        const data = response.map(item => ({
            Ajuste: item.Ajuste || "No hay registro",
            Cancelacion_valor: item.Cancelacion_valor || "No hay registro",
            Codigo_Punto: item.Codigo_Punto || "No hay registro",
            Comision: item.Comision || "No hay registro",
            ConsultaSaldo: item.ConsultaSaldo || "No hay registro",
            Cuenta_pagocomision: item.Cuenta_pagocomision || "No hay registro",
            CupoAprobado: item.CupoAprobado || "No hay registro",
            Email_Contacto: item.Email_Contacto || "No hay registro",
            Fecha_pago: item.Fecha_pago || "No hay registro",
            Mes: getMonthName(item.Mes) || "No hay registro",
            Monetarias: item.Monetarias || "No hay registro",
            Monetarias_valor: item.Monetarias_valor || "No hay registro",
            No_monetarias: item.No_monetarias || "No hay registro",
            No_monetarias_valor: item.No_monetarias_valor || "No hay registro",
            Nombre_Codigo: item.Nombre_Codigo || "No hay registro",
            Nombre_Gestor: item.Nombre_Gestor || "No hay registro",
            RecargaNequi: item.RecargaNequi || "No hay registro",
            RecargaNequi_Valor: item.RecargaNequi_Valor || "No hay registro",
            Recaudo: item.Recaudo || "No hay registro",
            RecaudoCodensa: item.RecaudoCodensa || "No hay registro",
            RecaudoCodensa_valor: item.RecaudoCodensa_valor || "No hay registro",
            Recaudo_valor: item.Recaudo_valor || "No hay registro",
            Retencion: item.Retencion || "No hay registro",
            Sura: item.Sura || "No hay registro",
            Tipo_Cuenta: item.Tipo_Cuenta || "No hay registro",
            TotalaPagar: item.TotalaPagar || "No hay registro",
            anio: item.anio || "No hay registro",
            cambio_clave: item.cambio_clave || "No hay registro",
            cambio_clave_valor: item.cambio_clave_valor || "No hay registro",
            carga: item.carga || "No hay registro",
            fecha_envio: item.fecha_envio || "No hay registro",
            pago_pendiente: item.pago_pendiente || "No hay registro",
            retiroaalamano: item.retiroaalamano || "No hay registro",
            retiroaalamano_valor: item.retiroaalamano_valor || "No hay registro",
            retironequi: item.retironequi || "No hay registro",
            retironequi_valor: item.retironequi_valor || "No hay registro",
            accion: <Tooltip title="Descargar pdf de comisión">
                <Button type="button" variant="contained" sx={{ textTransform: 'initial', borderRadius: 3 }}
                    onClick={(e) => {
                        e.preventDefault(); // Previene la navegación del enlace
                        accions(item.Codigo_Punto); // Pasa el ID al abrir el modal
                    }}
                >Descargar</Button></Tooltip>,
        }));

        const columns = [
            { field: 'Codigo_Punto', headerName: 'Código Punto' },
            { field: 'accion', headerName: 'Acción' },
            { field: 'Mes', headerName: 'Mes' },
            { field: 'anio', headerName: 'Año' },
            { field: 'Comision', headerName: 'Comisión' },
            { field: 'Ajuste', headerName: 'Ajuste' },
            { field: 'Cancelacion_valor', headerName: 'Cancelación Valor' },
            { field: 'ConsultaSaldo', headerName: 'Consulta Saldo' },
            { field: 'Cuenta_pagocomision', headerName: 'Cuenta Pago Comisión' },
            { field: 'CupoAprobado', headerName: 'Cupo Aprobado' },
            { field: 'Email_Contacto', headerName: 'Email Contacto' },
            { field: 'Fecha_pago', headerName: 'Fecha Pago' },
            { field: 'Monetarias', headerName: 'Monetarias' },
            { field: 'Monetarias_valor', headerName: 'Monetarias Valor' },
            { field: 'No_monetarias', headerName: 'No Monetarias' },
            { field: 'No_monetarias_valor', headerName: 'No Monetarias Valor' },
            { field: 'Nombre_Codigo', headerName: 'Nombre Código' },
            { field: 'Nombre_Gestor', headerName: 'Nombre Gestor' },
            { field: 'RecargaNequi', headerName: 'Recarga Nequi' },
            { field: 'RecargaNequi_Valor', headerName: 'Recarga Nequi Valor' },
            { field: 'Recaudo', headerName: 'Recaudo' },
            { field: 'RecaudoCodensa', headerName: 'Recaudo Codensa' },
            { field: 'RecaudoCodensa_valor', headerName: 'Recaudo Codensa Valor' },
            { field: 'Recaudo_valor', headerName: 'Recaudo Valor' },
            { field: 'Retencion', headerName: 'Retención' },
            { field: 'Sura', headerName: 'Sura' },
            { field: 'Tipo_Cuenta', headerName: 'Tipo Cuenta' },
            { field: 'TotalaPagar', headerName: 'Total a Pagar' },
            { field: 'cambio_clave', headerName: 'Cambio Clave' },
            { field: 'cambio_clave_valor', headerName: 'Cambio Clave Valor' },
            { field: 'carga', headerName: 'Carga' },
            { field: 'fecha_envio', headerName: 'Fecha Envío' },
            { field: 'pago_pendiente', headerName: 'Pago Pendiente' },
            { field: 'retiroaalamano', headerName: 'Retiro a la Mano' },
            { field: 'retiroaalamano_valor', headerName: 'Retiro a la Mano Valor' },
            { field: 'retironequi', headerName: 'Retiro Nequi' },
            { field: 'retironequi_valor', headerName: 'Retiro Nequi Valor' },
        ];

        return { columns, data };
    } catch (error) {
        console.error('Error al obtener los datos tableFacts: ', error);
    }
}



export const dataFactUser = async (mes, anio, text, page, loading, setTotalRows, accions) => {
    try {
        const response = await facts(mes, anio, text, page, setTotalRows);
        const userWh = await userVerify();

        const data = response.map(item => ({
            Ajuste: item.Ajuste || "No hay registro",
            Cancelacion_valor: item.Cancelacion_valor || "No hay registro",
            Codigo_Punto: item.Codigo_Punto || "No hay registro",
            Comision: item.Comision || "No hay registro",
            ConsultaSaldo: item.ConsultaSaldo || "No hay registro",
            Cuenta_pagocomision: item.Cuenta_pagocomision || "No hay registro",
            CupoAprobado: item.CupoAprobado || "No hay registro",
            Email_Contacto: item.Email_Contacto || "No hay registro",
            Fecha_pago: item.Fecha_pago || "No hay registro",
            Mes: getMonthName(item.Mes) || "No hay registro",
            Monetarias: item.Monetarias || "No hay registro",
            Monetarias_valor: item.Monetarias_valor || "No hay registro",
            No_monetarias: item.No_monetarias || "No hay registro",
            No_monetarias_valor: item.No_monetarias_valor || "No hay registro",
            Nombre_Codigo: item.Nombre_Codigo || "No hay registro",
            Nombre_Gestor: item.Nombre_Gestor || "No hay registro",
            RecargaNequi: item.RecargaNequi || "No hay registro",
            RecargaNequi_Valor: item.RecargaNequi_Valor || "No hay registro",
            Recaudo: item.Recaudo || "No hay registro",
            RecaudoCodensa: item.RecaudoCodensa || "No hay registro",
            RecaudoCodensa_valor: item.RecaudoCodensa_valor || "No hay registro",
            Recaudo_valor: item.Recaudo_valor || "No hay registro",
            Retencion: item.Retencion || "No hay registro",
            Sura: item.Sura || "No hay registro",
            Tipo_Cuenta: item.Tipo_Cuenta || "No hay registro",
            TotalaPagar: item.TotalaPagar || "No hay registro",
            anio: item.anio || "No hay registro",
            cambio_clave: item.cambio_clave || "No hay registro",
            cambio_clave_valor: item.cambio_clave_valor || "No hay registro",
            carga: item.carga || "No hay registro",
            fecha_envio: item.fecha_envio || "No hay registro",
            pago_pendiente: item.pago_pendiente || "No hay registro",
            retiroaalamano: item.retiroaalamano || "No hay registro",
            retiroaalamano_valor: item.retiroaalamano_valor || "No hay registro",
            retironequi: item.retironequi || "No hay registro",
            retironequi_valor: item.retironequi_valor || "No hay registro",
            accion: <Tooltip title="Descargar pdf de comisión">
                <Button type="button" variant="contained" disabled={loading} sx={{ textTransform: 'initial', borderRadius: 3 }}
                    onClick={(e) => {
                        e.preventDefault(); // Previene la navegación del enlace
                        accions.downloadPdf(item.Codigo_Punto); // Pasa el ID al abrir el modal
                    }}
                >Descargar</Button></Tooltip>,
            Wh: <Tooltip title="Mira los datos resumidos en tu WhatsApp">
                <Button type="button" variant="contained" disabled={!userWh || loading} sx={{ textTransform: 'initial', borderRadius: 3, background: 'green' }}
                    onClick={(e) => {
                        e.preventDefault(); // Previene la navegación del enlace
                        accions.sendWhatsapp(item.Codigo_Punto); // Pasa el ID al abrir el modal
                    }}
                >{loading ? <CircularProgress size={24} color="inherit" /> : <FaWhatsapp size={25} />}</Button></Tooltip>,
        }));

        const columns = [
            { field: 'Codigo_Punto', headerName: 'Código Punto' },
            { field: 'accion', headerName: 'Acción' },
            { field: 'Wh', headerName: 'Mesaje a WhatsApp' },
            { field: 'Mes', headerName: 'Mes' },
            { field: 'anio', headerName: 'Año' },
            { field: 'Comision', headerName: 'Comisión' },
            { field: 'Ajuste', headerName: 'Ajuste' },
            { field: 'Cancelacion_valor', headerName: 'Cancelación Valor' },
            { field: 'ConsultaSaldo', headerName: 'Consulta Saldo' },
            { field: 'Cuenta_pagocomision', headerName: 'Cuenta Pago Comisión' },
            { field: 'CupoAprobado', headerName: 'Cupo Aprobado' },
            { field: 'Email_Contacto', headerName: 'Email Contacto' },
            { field: 'Fecha_pago', headerName: 'Fecha Pago' },
            { field: 'Monetarias', headerName: 'Monetarias' },
            { field: 'Monetarias_valor', headerName: 'Monetarias Valor' },
            { field: 'No_monetarias', headerName: 'No Monetarias' },
            { field: 'No_monetarias_valor', headerName: 'No Monetarias Valor' },
            { field: 'Nombre_Codigo', headerName: 'Nombre Código' },
            { field: 'Nombre_Gestor', headerName: 'Nombre Gestor' },
            { field: 'RecargaNequi', headerName: 'Recarga Nequi' },
            { field: 'RecargaNequi_Valor', headerName: 'Recarga Nequi Valor' },
            { field: 'Recaudo', headerName: 'Recaudo' },
            { field: 'RecaudoCodensa', headerName: 'Recaudo Codensa' },
            { field: 'RecaudoCodensa_valor', headerName: 'Recaudo Codensa Valor' },
            { field: 'Recaudo_valor', headerName: 'Recaudo Valor' },
            { field: 'Retencion', headerName: 'Retención' },
            { field: 'Sura', headerName: 'Sura' },
            { field: 'Tipo_Cuenta', headerName: 'Tipo Cuenta' },
            { field: 'TotalaPagar', headerName: 'Total a Pagar' },
            { field: 'cambio_clave', headerName: 'Cambio Clave' },
            { field: 'cambio_clave_valor', headerName: 'Cambio Clave Valor' },
            { field: 'carga', headerName: 'Carga' },
            { field: 'fecha_envio', headerName: 'Fecha Envío' },
            { field: 'pago_pendiente', headerName: 'Pago Pendiente' },
            { field: 'retiroaalamano', headerName: 'Retiro a la Mano' },
            { field: 'retiroaalamano_valor', headerName: 'Retiro a la Mano Valor' },
            { field: 'retironequi', headerName: 'Retiro Nequi' },
            { field: 'retironequi_valor', headerName: 'Retiro Nequi Valor' },
        ];


        return { columns, data };
    } catch (error) {
        console.error('Error al obtener los datos tableFacts: ', error);
    }
}