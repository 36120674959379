import React from 'react';

import PropTypes from 'prop-types';
import { Modal } from '@mui/material';

function ModalBig({ openDialog, handleCloseDialog, children }) {

    return (
        <Modal
            open={openDialog}
            onClose={handleCloseDialog}
            sx={{ overflow: 'visible' }}
            aria-labelledby="modal-modal-title"
            BackdropProps={{
                style: {
                    position: 'absolute',
                    backgroundColor: 'rgb(66 66 66 / 50%)',
                    backdropFilter: 'blur(3px)'
                }
            }}
            aria-describedby="modal-modal-description"
        >
            {children}
        </Modal>
    );
}

ModalBig.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default ModalBig;