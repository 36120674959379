import { Checkgeneral, ErrorGeneral, Infogeneral } from "../../../../components/Alerts";
import Axios from "../../../../config/axios";
import { URL_USER } from "../../../../constanst/link";
import { isAuthenticated } from "../../../../context/verificationUser";

export const createUsers = async (setLoading, formValues, handleRefresh) => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        setLoading(false);
        return;
    }

    if (!formValues || formValues.length === 0) {
        console.error("No file provided");
        setLoading(false);
        Infogeneral('No se proporcionó datos para crear.');
        return;
    }

    try {
        setLoading(true);
        const response = await Axios.post(URL_USER, formValues);

        if (response.status === 200) {
            Checkgeneral('Se ha creado el usuario');
            setTimeout(function () {
                handleRefresh();
            }, 1000);
        } else {
            ErrorGeneral('La respuesta del servidor no es 200.');
        }

    } catch (error) {
        console.error('Error al subir:', error);
        setLoading(false);
        ErrorGeneral('Vaya... tuvimos un problema al crear el usuario.');
    } finally {
        setLoading(false);
    }
};