import Axios from "../../../../config/axios";
import { Checkgeneral, ErrorGeneral } from "../../../../components/Alerts";
import { isAuthenticated } from "../../../../context/verificationUser";
import { URL_USER_ADD } from "../../../../constanst/link";

export const addUsers = async (setLoading, file, handleRefresh) => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        setLoading(false);
        return;
    }

    if (!file || file.length === 0) {
        console.error("No file provided");
        setLoading(false);
        ErrorGeneral('No se proporcionó un archivo.');
        return;
    }

    try {
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true);

        const response = await Axios.post(URL_USER_ADD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            Checkgeneral('Se ha cargado el archivo de usuarios');
            setTimeout(function () {
                handleRefresh();
            }, 2000);
        } else {
            ErrorGeneral('La respuesta del servidor no es 200.');
        }

    } catch (error) {
        console.error('Error al subir:', error);
        setLoading(false);
        ErrorGeneral('Vaya... tuvimos un problema al subir el archivo.');
    } finally {
        setLoading(false);
    }
};