import Axios from "../../../config/axios";
import { URL_USER } from "../../../constanst/link";
import { isAuthenticated } from "../../../context/verificationUser";

export const users = async (searchType, text, page, setTotalRows) => {
    let pageSize = 50;

    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    let queryParams = `?page=${page}&pageSize=${pageSize}`;

    if (searchType === 'codigo') {
        queryParams += `&CodigoPunto=${text}&numero_celular=`;
    } else if (searchType === 'telefono') {
        queryParams += `&numero_celular=${text}&CodigoPunto=`;
    }

    try {
        const response = await Axios.get(`${URL_USER}${queryParams}`);

        if (response.status === 200) {
            setTotalRows(response.data.total);
            return response.data.Founded.reverse();
        }
    } catch (error) {
        console.error('Error al traer datos de usuario en base de datos:', error);
    }
};