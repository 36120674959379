import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, TextField, Typography } from '@mui/material';
// import Cookies from 'js-cookie';

//components
import ModalLittle from '../../components/Modal/modalLittle';
import MarginContent from "../../components/MarginContent";
import { dataTemporal } from './data/tabla';
import Tabla from '../../components/Tabla';
import Menu from '../../components/Menu';
import { process } from './data/getProcess';
import { processVerify } from './data/getVerifyProcess';
//icons
// import { FaWhatsapp } from "react-icons/fa";

function Temporal() {
    const [text, setText] = useState('');

    const [loading2, setLoading2] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);

    const [totalRows, setTotalRows] = useState(0)
    const [page, setPage] = useState(1);

    const [dataView, setDataView] = useState({ columns: [], data: [] });
    const { columns, data } = dataView;

    const handleCloseDialog2 = () => { setOpenDialog2(false); };
    const handleChange = (event) => { setText(event.target.value); setPage(1) };

    const handleProcesarDatos = async () => {
        setLoading2(false);
        setOpenDialog2(true);
    };

    const succesClickProcesar = async () => {
        setLoading2(true);
        setOpenDialog2(false);
        await process();
        await processVerify();
        await new Promise((resolve) => setTimeout(resolve, 2000));
    };

    useEffect(() => {
        setLoading2(true);
        const getTableData = async () => {
            const tableData = await processVerify();
            setLoading2(tableData);
        };

        getTableData();

        const intervalId = setInterval(() => {
            getTableData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const getTableData = async () => {
            const tableData = await dataTemporal(text, page, setTotalRows);
            if (tableData) {
                setDataView(tableData);
            }
        };
        getTableData();
    }, [totalRows, text, page]);

    return (
        <>
            <MarginContent>
                <Menu />
                <Card sx={{ p: 2, borderRadius: 5, border: 'none', marginTop: 4, marginBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Acciones

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center',  justifyContent: 'center' }}>
                        <TextField
                            label="Busca código punto"
                            variant="outlined"
                            name="usuario"
                            value={text}
                            disabled={loading2}
                            onChange={handleChange}
                            margin="normal"
                            sx={{
                                margin: 0,
                                '& .MuiInputBase-root': {
                                    height: '36px',
                                    borderRadius: 3,
                                    // alignItems: 'center'
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '15px',
                                    top: '-7px'
                                },
                                '& .MuiInputBase-input': {
                                    height: '35px',
                                    // padding: '0 14px' // Ajusta el padding del input
                                },
                            }}
                        />
                        {loading2 && <Typography sx={{color: 'gray', fontSize: 16}}>Se está procesando</Typography>}
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            disabled={loading2}
                            onClick={handleProcesarDatos}
                            sx={{ textTransform: 'initial', backgroundColor: 'var(--color-principal)', borderRadius: 3 }}
                        >
                            {loading2 ? <CircularProgress size={24} color="inherit" /> : 'Procesar datos'}
                        </Button>
                    </Box>
                </Card>

                <Tabla columns={columns} data={data} totalRows={totalRows} page={page} setPage={setPage} />
            </MarginContent>

            <ModalLittle
                openDialog={openDialog2}
                handleCloseDialog={handleCloseDialog2}
                succesClick={succesClickProcesar}
                dialogTitle="Confirmación de proceso"
                dialogContent="¿Estás seguro de querer realizar esta acción?"
            />
        </>
    );
}

export default Temporal;