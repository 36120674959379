import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PropTypes from 'prop-types';

function ModalLittle({ openDialog, handleCloseDialog, succesClick, dialogTitle, dialogContent }) {

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="customized-dialog-title"
            aria-describedby="customized-dialog-description"
            sx={{
                bgcolor: 'background.paper',
                backgroundColor: 'rgb(255 255 255 / 6%)',
                backdropFilter: 'blur(3px)',
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        borderRadius: '16px'
                    }
                }
            }}>
            <DialogTitle id="alert-dialog-title">
                {dialogTitle || "Título del Diálogo"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContent || "Contenido del Diálogo"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseDialog}
                    style={{ color: "white", background: "red", textTransform: "initial" }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    autoFocus
                    onClick={succesClick}
                    style={{ color: "white", background: "#0aa94d", textTransform: "initial" }}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalLittle.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    succesClick: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.string,
};

export default ModalLittle;