import React, { useState, useRef } from 'react';
import { Box, Button, Input, Typography } from '@mui/material';
import PropTypes from 'prop-types';

//components
import ModalBig from '../../../components/Modal/modalBig';
import { ErrorGeneral, Infogeneral } from '../../../components/Alerts';
import { addUsers } from '../data/triggers/postAddUser';
// import { add } from '../data/triggers/postRates';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
};

function AddUserImport({ handleRefresh, openDialog, handleCloseDialog, setLoading }) {

    const [file, setFile] = useState(null);
    const inputFileRef = useRef(null);

    const handleSubmitImport = async (e) => {
        e.preventDefault();
        setLoading(true);
        handleCloseDialog();

        try {
            await addUsers(setLoading, file, handleRefresh);
            setFile(null);
        } catch (error) {
            console.error('Error en handleSubmit:', error);
            setLoading(false);
            ErrorGeneral('Vaya... tuvimos un problema en el botom');
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(file);
        } else {
            Infogeneral("Debes seleccionar un archivo .xlsx");
        }
    };

    return (
        <ModalBig openDialog={openDialog} handleCloseDialog={handleCloseDialog}>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Sube tu archivo con los usuarios .xlsx
                </Typography>
                <label htmlFor="contained-button-file">
                    <Input
                        accept=".xlsx"
                        id="contained-button-file"
                        multiple
                        ref={inputFileRef}
                        placeholder='escoge tu archivo'
                        type="file"
                        onChange={handleFileChange}
                        style={{ color: "black", textTransform: "initial", borderRadius: 3 }}
                    />
                </label>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitImport}
                    sx={{ textTransform: 'initial', backgroundColor: 'var(--color-principal)', borderRadius: 3 }}
                >
                    Subir archivo
                </Button>
            </Box>
        </ModalBig>
    );
}

AddUserImport.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default AddUserImport;