import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { download } from '../data/download';
import { Checkgeneral, ErrorGeneral, Infogeneral } from '../../../components/Alerts';
import { add } from '../data/trigger/postCommission';
import * as XLSX from 'xlsx';
import { processVerify } from '../../temporal/data/getVerifyProcess';

const Add = () => {

    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [disableds, setDisabbles] = useState(false);

    const handleSubmitDownload = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await download(setLoading);
            Checkgeneral('Espera unos segundos mientras carga el archivo');
        } catch (error) {
            console.error('Error al entrar handleSubmit:', error);
            ErrorGeneral('Vaya... tuvimos un problema en el botom');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading2(true);

        try {
            await add(setLoading2, file);
        } catch (error) {
            console.error('Error en handleSubmit:', error);
            setLoading2(false);
            ErrorGeneral('Vaya... tuvimos un problema en el botom');
        }
    };

    const MAX_ROWS = 5000;
    const addFile = (file) => {
        const isExcel = file.type === "application/vnd.ms-excel" ||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
            file.type === "application/vnd.ms-excel.sheet.binary.macroEnabled.12";

        if (isExcel) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const range = XLSX.utils.decode_range(worksheet['!ref']);

                const numberOfRows = range.e.r + 1; // +1 porque las filas comienzan en 0

                if (numberOfRows > MAX_ROWS) {
                    Infogeneral(`El archivo tiene más de ${MAX_ROWS} filas.`);
                    return;
                }

                setFiles([file]);
                setFile(file);
            };

            reader.readAsArrayBuffer(file);
        } else {
        console.log(counter);
        Infogeneral('Solo se permite archivos excel (.xlsx)');
        }
    };

    const handleFilesChange = (e) => {
        for (const file of e.target.files) {
            addFile(file);
        }
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        for (const file of ev.dataTransfer.files) {
            addFile(file);
        }
        setCounter(0);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        if (e.dataTransfer.types.indexOf("Files") > -1) {
            setCounter((prevCounter) => prevCounter + 1);
        }
    };

    const handleDragLeave = (e) => {
        setCounter((prevCounter) => prevCounter - 1);
    };

    const handleDragOver = (e) => {
        if (e.dataTransfer.types.indexOf("Files") > -1) {
            e.preventDefault();
        }
    };

    const handleDelete = (key) => {
        setFiles((prevFiles) => {
            const newFiles = { ...prevFiles };
            delete newFiles[key];
            return newFiles;
        });
        setFile([]);
    };

    const handleCancel = () => {
        setFiles({});
        setFile([]);
    };

    useEffect(() => {
        setDisabbles(true);
        const getTableData = async () => {
            const tableData = await processVerify();
            setDisabbles(tableData);
        };

        getTableData();

        const intervalId = setInterval(() => {
            getTableData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <main className="container mx-auto max-w-screen-lg rounded-lg h-full">
            <article
                aria-label="File Upload Modal"
                className="relative h-full flex flex-col bg-white shadow-xl rounded-lg"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDragEnter={handleDragEnter}
            >
                <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                    <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
                        <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                            <span>Cargar archivos</span>
                        </p>
                        <input id="hidden-input" type="file" multiple className="hidden" onChange={handleFilesChange} />
                        <button id="button" className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none" onClick={() => document.getElementById("hidden-input").click()}>
                            Abrir carpeta de archivos
                        </button>
                    </header>
                    <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                        Visualización
                    </h1>
                    <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
                        {Object.keys(files).length === 0 ? (
                            <li id="empty" className="h-full w-full text-center flex flex-col items-center justify-center items-center">
                                <img className="mx-auto w-32" src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png" alt="no data" />
                                <span className="text-small text-gray-500">No se ha seleccionado el archivo</span>
                            </li>
                        ) : (
                            Object.keys(files).map((key) => {
                                const file = files[key];
                                return (
                                    <li key={key} className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
                                        <article tabIndex="0" className={`group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm ${file.isImage ? 'hasImage' : ''}`}>
                                            {file.isImage && <img alt="upload preview" className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed" src={file.src} />}
                                            <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                                <h1 className="flex-1 group-hover:text-blue-800">{file.name}</h1>
                                                <div className="flex">
                                                    <span className="p-1 text-blue-800">
                                                        <i>
                                                            <svg className="fill-current w-4 h-4 ml-auto pt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                                            </svg>
                                                        </i>
                                                    </span>

                                                    <p className="p-1 size text-xs text-gray-700">{file.size}</p>
                                                    <button className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800" onClick={() => handleDelete(key)}>
                                                        <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </section>
                                        </article>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </section>
                <footer className="flex justify-end px-8 pb-8 pt-4" style={{ display: 'flex', gap: 9, alignItems: 'center', flexWrap: 'wrap' }}>
                    {disableds && <Typography sx={{ color: 'gray', fontSize: 16 }}>Se está procesando una comisión</Typography>}
                    <Button disabled={disableds} type="button" variant="contained" sx={{ textTransform: 'initial', backgroundColor: disableds ? 'rgba(0, 0, 0, 0.26)' : 'var(--color-principal)', borderRadius: 3 }} onClick={handleSubmit}>
                        {loading2 ? <CircularProgress size={24} color="inherit" /> : "Cargar archivo"}
                    </Button>
                    <Button disabled={disableds} type="button" variant="contained" sx={{ textTransform: 'initial', backgroundColor: disableds ? 'rgba(0, 0, 0, 0.26)' : 'var(--color-secundario)', borderRadius: 3 }} onClick={handleSubmitDownload}  >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Descargar plantilla de excel"}
                    </Button>
                    <Button disabled={disableds} type="button" variant="contained" sx={{ textTransform: 'initial', backgroundColor: disableds ? 'rgba(0, 0, 0, 0.26)' : 'red', borderRadius: 3 }} onClick={handleCancel}>
                        Borrar archivos
                    </Button>
                </footer>
            </article>
        </main>
    );
};

export default Add;