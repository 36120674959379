import Axios from "../../config/axios";
import { URL_USER } from "../../constanst/link";
import { getDecryptedUserObject } from "../../context/aunth";
import { isAuthenticated } from "../../context/verificationUser";

export const userVerify = async () => {
    const verify = isAuthenticated();
    const user = getDecryptedUserObject();

    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    try {
        const response = await Axios.get(`${URL_USER}?CodigoPunto=${user.username}&numero_celular=`);

        if (response.status === 200) {
            return response.data.Founded[0].wh;
        }
    } catch (error) {
        console.error('Error al traer datos de usuario en base de datos:', error);
    }
};