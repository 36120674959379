import { getMonth, getMonthName } from "../../../utils/getMonth/index.";
import { Button, Tooltip } from "@mui/material";
import { rates } from "./getRates";

const { mes: mesActual, anio: anioActual } = getMonth();

export const dataRates = async (page, setTotalRows, accions) => {
    try {
        const response = await rates(page, setTotalRows);

        const data = response.map(rate => {
            const mesAnterior = mesActual === 1 ? 12 : mesActual - 1;
            const puedeEditar = (rate.Mes === mesActual && rate.Año === anioActual) ||
            (rate.Mes === mesAnterior && rate.Año === anioActual);
        
            return {
                id: rate.Idx_Tarifa,
                month: getMonthName(rate.Mes),
                age: rate.Año,
                pais: rate.Id_Pais,
                valueRate: rate.Valor_Tarifa,
                group: rate.Grupo_Transaccion,
                accion: (
                    <Tooltip title="Edita esta tarifa">
                        <span>
                            <Button
                                type="button"
                                variant="contained"
                                sx={{ textTransform: 'initial', borderRadius: 3 }}
                                onClick={(e) => {
                                    e.preventDefault(); // Previene la navegación del enlace
                                    accions(rate.Idx_Tarifa, rate.Valor_Tarifa); // Pasa el ID al abrir el modal
                                }}
                                disabled={!puedeEditar}
                            >
                                Editar
                            </Button>
                        </span>
                    </Tooltip>
                ),
            };
        });
        const columns = [
            { field: 'id', headerName: 'Tarifa' },
            { field: 'accion', headerName: 'Acción' },
            { field: 'age', headerName: 'Año' },
            { field: 'month', headerName: 'Mes' },
            { field: 'pais', headerName: 'Pais' },
            { field: 'valueRate', headerName: 'Valor de tarifa' },
            { field: 'group', headerName: 'Grupo de transacción' },
        ];

        return { columns, data };
    } catch (error) {
        console.error('Error al obtener los datos tableRates: ', error);
    }
}