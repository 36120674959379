import Axios from "../../../../config/axios";
import { URL_TARIFAS } from "../../../../constanst/link";
import { isAuthenticated } from "../../../../context/verificationUser";
import { Checkgeneral, ErrorGeneral } from "../../../../components/Alerts";

export const put = async ( rates, idRate, handleRefresh) => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    if (!rates || rates.length === 0) {
        console.error("No file provided");
        ErrorGeneral('No se proporcionó ningun dato.');
        return;
    }

    try {
        const response = await Axios.put(`${URL_TARIFAS}?Idx_Tarifa=${idRate}`, rates);

        if (response.status === 200) {
            Checkgeneral(`Se ha actualizado la tarifa ${idRate}`);
            setTimeout(function () {
                handleRefresh();
            }, 2000);
        } else {
            ErrorGeneral('La respuesta del servidor ocurrio un error.');
        }

    } catch (error) {
        console.error('Error al subir:', error);
        ErrorGeneral('Vaya... tuvimos un problema al actualizar.');
    } finally {
        handleRefresh();
    }
};