import React from 'react';
import Dashboard from "./index";
import DashboardUsers from "./indexUsers";
import { hasRole, isAuthenticated } from "../../context/verificationUser";

const DashboardGate = () => {
    const verify = isAuthenticated();

    if (!verify) {
        return ;
    }

    if (hasRole("ADMIN")) {
        return <Dashboard />;
    } else if (hasRole("CORRESPONSALES")) {
        return <DashboardUsers />;
    } else {
        return ;
    }
};

export default DashboardGate;