import React from 'react';

// components
import Add from './addCommission';
import Menu from '../../components/Menu';
import MarginContent from '../../components/MarginContent';

function Commission() {

    return (
        <>
            <MarginContent>
                <Menu />
                <Add />
            </MarginContent>
        </>
    );
}

export default Commission;