import Axios from "../../../config/axios";
import { URL_TARIFAS } from "../../../constanst/link";
import { ErrorGeneral } from "../../../components/Alerts";
import { isAuthenticated } from "../../../context/verificationUser";

export const rates = async (page, setTotalRows) => {
    let pageSize = 50

    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    try {
        const response = await Axios.get(`${URL_TARIFAS}?pageSize=${pageSize}&page=${page}`);

        if (response.status === 200) {
            setTotalRows(response.data.total)
            return response.data.Founded;
        }
    } catch (error) {
        console.error('Error al cargar:', error);
        ErrorGeneral('Vaya... tubimos un problema al cargar tarifas');
    }
};