import Axios from "../../../config/axios";
import FileDownload from 'js-file-download';
import { URL_COMMISSION_PDF } from "../../../constanst/link";
import { isAuthenticated } from "../../../context/verificationUser";
import { Checkgeneral, ErrorGeneral } from "../../../components/Alerts";

export const PDF = async (mes, anio, idDonwloader) => {
    const verify = isAuthenticated();
    if (!verify || !mes || !anio || !idDonwloader) {
        console.error("User parameters are missing");
        return;
    }

    let queryParams = `?mes=${mes}&anio=${anio}&CodigoPunto=${idDonwloader}`;

    try {
        const response = await Axios.get(`${URL_COMMISSION_PDF}${queryParams}`, {
            responseType: 'blob'
        });

        if (response.status === 200) {
            FileDownload(response.data, "comision.pdf");
            Checkgeneral('Espera unos segundos para generar el documento');
        } else {
            console.error('Error al cargar:', response);    
            ErrorGeneral('Error al cargar el documento');
        }
    } catch (error) {
        console.error('Error al cargar:', error);
        ErrorGeneral(error.response?.data || 'Error al cargar el documento');
    }
}