import Cookies from 'js-cookie';
import { getDecryptedPayloadObject, getDecryptedUserObject } from './aunth';

export const isAuthenticated = () => {
    const userObject = getDecryptedUserObject();
    return Boolean(userObject && userObject.ID && userObject.active);
};

export const getPayload = () => {
    const tokenObject = getDecryptedPayloadObject();
    return tokenObject ? tokenObject : null;
};

export const isAuthenticatedDispach = async () => {
    Cookies.remove('object');
    Cookies.remove('payload');

    window.location.href = '/signIn';
    localStorage.clear();
};

export const hasRole = (requiredRoles) => {
    const userObject = getDecryptedUserObject();
    if (!userObject || !userObject.groupid) {
        return false;
    }

    if (!Array.isArray(requiredRoles)) {
        requiredRoles = [requiredRoles];
    }

    return requiredRoles.some(role => userObject.groupid.includes(role));
};