import React, { useEffect, useState } from 'react';
import { Box, Card, MenuItem, Select, TextField } from '@mui/material';

// components
import Menu from '../../components/Menu/index';
import MarginContent from '../../components/MarginContent';
import Tabla from '../../components/Tabla';
import { dataFactAdmin } from './data/tabla';
import { ErrorGeneral } from '../../components/Alerts';
import { PDF } from './data/getPdf';

function Dashboard() {

    const [dataView, setDataView] = useState({ columns: [], data: [] });
    const { columns, data } = dataView;

    const [totalRows, setTotalRows] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [page, setPage] = useState(1);

    const [text, setText] = useState('');
    const [mes, setMes] = useState(new Date().getMonth() + 1);
    const [anio, setAnio] = useState(new Date().getFullYear());

    const meses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }

    const handleChange = (event) => { setText(event.target.value); setPage(1) };
    const handleRefresh = () => { setRefresh(prevRefresh => prevRefresh + 1); };

    useEffect(() => {
        const downloadPdf = async (id) => {
            handleRefresh();

            try {
                await PDF(mes, anio, id);
            } catch (error) {
                console.error('Error en handleSubmit:', error);
                ErrorGeneral('Vaya... tuvimos un problema en el boton');
            }
        };

        const getTableData = async () => {
            const tableData = await dataFactAdmin(mes, anio, text, page, setTotalRows, (id) => {
                downloadPdf(id);
            });
            if (tableData) {
                setDataView(tableData);
            }
        };

        getTableData();
    }, [refresh, mes, anio, text, page, setTotalRows]);

    return (
        <>
            <MarginContent>
                <Menu />
                <Card sx={{ p: 2, borderRadius: 5, border: 'none', marginTop: 4, marginBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Acciones

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        <TextField
                            label="Buscar código punto"
                            variant="outlined"
                            name="usuario"
                            value={text}
                            onChange={handleChange}
                            margin="normal"
                            sx={{
                                margin: 0,
                                '& .MuiInputBase-root': {
                                    height: '36px',
                                    borderRadius: 3,
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '15px',
                                    top: '-7px'
                                },
                                '& .MuiInputBase-input': {
                                    height: '35px',
                                },
                            }}
                        />

                        <Select
                            value={mes}
                            displayEmpty
                            onChange={(e) => setMes(e.target.value)}
                            sx={{
                                height: '36px',
                                borderRadius: 3,
                                minWidth: '140px',
                                '& .MuiSelect-root': {
                                    paddingTop: '9px',
                                },
                                '& .MuiInputBase-root': {
                                    borderRadius: 3,
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '15px',
                                    top: '-7px'
                                },
                            }}
                        >
                            <MenuItem value="" disabled>Mes</MenuItem>
                            {meses.map((mesNombre, index) => (
                                <MenuItem key={index} value={index + 1}>{mesNombre}</MenuItem>
                            ))}
                        </Select>

                        <Select
                            value={anio}
                            displayEmpty
                            onChange={(e) => setAnio(e.target.value)}
                            sx={{
                                height: '36px',
                                borderRadius: 3,
                                minWidth: '140px',
                                '& .MuiSelect-root': {
                                    paddingTop: '9px',
                                },
                                '& .MuiInputBase-root': {
                                    borderRadius: 3,
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '15px',
                                    top: '-7px'
                                },
                            }}
                        >
                            <MenuItem value="" disabled>Año</MenuItem>
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Card>


                <Tabla columns={columns} data={data} totalRows={totalRows} page={page} setPage={setPage} />
            </MarginContent>
        </>
    );
}

export default Dashboard;