import axios from "axios";
import { URL_BASE, URL_KEY } from "../../constanst/link";
import { getPayload } from "../../context/verificationUser";

const Axios = axios.create({
  baseURL: URL_BASE,
  headers: {
    'api': URL_KEY,
    'token': getPayload()
  }
});

export default Axios;