import Axios from "../../../config/axios";
import FileDownload from 'js-file-download';
import { URL_COMMISSION } from "../../../constanst/link";
import { ErrorGeneral } from "../../../components/Alerts";
import { isAuthenticated } from "../../../context/verificationUser";

export const download = async (setLoading) => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        setLoading(false);
        return;
    }

    try {
        setLoading(true);
        const response = await Axios.get(URL_COMMISSION, {
            responseType: 'blob' 
        });

        FileDownload(response.data, "Escribe_el_nombre_del_archivo.xlsx");
        
    } catch (error) {
        console.error('Error al descargar:', error);
        ErrorGeneral('Vaya... tubimos un problema al descargar');
    } finally {
        setLoading(false);
    }
};
