import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

//components
import ModalBig from '../../../components/Modal/modalBig';
import { ErrorGeneral, Infogeneral } from '../../../components/Alerts';
import { createUsers } from '../data/triggers/postCreateUser';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
};

function CreateUser({ handleRefresh, openDialog, handleCloseDialog, setLoading }) {

    const [formValues, setFormValues] = useState({
        username: '',
        email: '',
        fullname: '',
        groupid: '',
        password: '',
        numero_celular: ''
    });
    const [errors, setErrors] = useState({});

    const deleteValues = async (e) => {
        setFormValues({
            username: '',
            email: '',
            fullname: '',
            groupid: '',
            password: '',
            numero_celular: ''
        })
    }

    const validate = () => {
        const newErrors = {};

        if (!formValues.email) newErrors.email = 'El campo Correo Electrónico es obligatorio';
        if (!formValues.fullname) newErrors.fullname = 'El campo Nombre Completo es obligatorio';
        if (!formValues.groupid) newErrors.groupid = 'El campo ID del Grupo es obligatorio';
        if (!formValues.password) newErrors.password = 'El campo Contraseña es obligatorio';
        if (!formValues.username) newErrors.username = 'El campo Nombre de Usuario es obligatorio';

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!validate()) {
            Infogeneral('Debes rellenar todos los campos');
            deleteValues();
            handleCloseDialog();
            setLoading(false);
            return;
        }

        setLoading(true);
        handleCloseDialog();

        try {
            await createUsers(setLoading, formValues, handleRefresh);
            handleRefresh();
            deleteValues();
        } catch (error) {
            console.error('Error en handleSubmit:', error);
            setLoading(false);
            ErrorGeneral('Vaya... tuvimos un problema en el botón');
        }
    };

    return (
        <ModalBig openDialog={openDialog} handleCloseDialog={handleCloseDialog}>
            <Box sx={style} onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Crear nuevo usuario
                </Typography>
                <form >
                    <TextField
                        label="Nombre de Usuario"
                        name="username"
                        value={formValues.username}
                        onChange={handleChange}
                        fullWidth
                        isRequired
                        error={!!errors.username}
                        margin="normal"
                    />
                    {errors.username && <Typography variant="caption" color="error">{errors.username}</Typography>}
                    <TextField
                        label="Correo Electrónico"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.email}
                        margin="normal"
                    />
                    {errors.email && <Typography variant="caption" color="error">{errors.email}</Typography>}
                    <TextField
                        label="Nombre Completo"
                        name="fullname"
                        value={formValues.fullname}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.fullname}
                        margin="normal"
                    />
                    {errors.fullname && <Typography variant="caption" color="error">{errors.fullname}</Typography>}
                    <FormControl fullWidth margin="normal" error={!!errors.groupid}>
                        <InputLabel htmlFor="groupid">ID del Grupo</InputLabel>
                        <Select
                            id="groupid"
                            name="groupid"
                            value={formValues.groupid}
                            onChange={handleChange}
                        >
                            <MenuItem value="CORRESPONSALES">CORRESPONSALES</MenuItem>
                            <MenuItem value="ADMIN">ADMIN</MenuItem>
                        </Select>
                        {errors.groupid && <Typography variant="caption" color="error">{errors.groupid}</Typography>}
                    </FormControl>
                    <TextField
                        label="Número de teléfono"
                        name="numero_celular"
                        value={formValues.numero_celular}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.numero_celular}
                        margin="normal"
                    />
                    <TextField
                        label="Contraseña"
                        name="password"
                        type="password"
                        value={formValues.password}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.password}
                        margin="normal"
                    />
                    {errors.password && <Typography variant="caption" color="error">{errors.password}</Typography>}
                    <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: 'initial', backgroundColor: 'var(--color-principal)', borderRadius: 3 }}
                        >
                            Crear
                        </Button>
                        <Button
                            onClick={() => { handleCloseDialog(); deleteValues(); }}
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: 'initial', backgroundColor: 'red', borderRadius: 3 }}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </form>
            </Box>
        </ModalBig>
    );
}

CreateUser.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default CreateUser;