import { getMonthName } from "../../../utils/getMonth/index.";
import { temporal } from "./getTemporal";


export const dataTemporal = async (text, page, setTotalRows) => {
    try {
        const response = await temporal(text,page, setTotalRows);

        const data = response.map(tempo => ({
            CodigoPunto: tempo.CodigoPunto ?? "No tiene registro",
            AhorroAlaMano: tempo.AhorroAlaMano ?? "No tiene registro",
            Mes: getMonthName(tempo.MesNumero) ?? "No tiene registro",
            Ajuste: tempo.Ajuste ?? "No tiene registro",
            AnioPago: tempo.AnioPago ?? "No tiene registro",
            CambioClave: tempo.CambioClave ?? "No tiene registro",
            Comsion: tempo.Comsion ?? "No tiene registro",
            ConsultaSaldo: tempo.ConsultaSaldo ?? "No tiene registro",
            CuentaPagocomision: tempo.CuentaPagocomision ?? "No tiene registro",
            CupoAprobado: tempo.CupoAprobado ?? "No tiene registro",
            Fecha: tempo.Fecha ?? "No tiene registro",
            FechaPago: tempo.FechaPago ?? "No tiene registro",
            Monetarias: tempo.Monetarias ?? "No tiene registro",
            Nit: tempo.Nit ?? "No tiene registro",
            No_monetarias: tempo.No_monetarias ?? "No tiene registro",
            NombreCodigo: tempo.NombreCodigo ?? "No tiene registro",
            NombreGestor: tempo.NombreGestor ?? "No tiene registro",
            NombreVendedor: tempo.NombreVendedor ?? "No tiene registro",
            PagoPendiente: tempo.PagoPendiente ?? "No tiene registro",
            RecargaNequi: tempo.RecargaNequi ?? "No tiene registro",
            RecargaNequi_Valor: tempo.RecargaNequi_Valor ?? "No tiene registro",
            Recaudo: tempo.Recaudo ?? "No tiene registro",
            RecaudoCodensa: tempo.RecaudoCodensa ?? "No tiene registro",
            Retencion: tempo.Retencion ?? "No tiene registro",
            Sura: tempo.Sura ?? "No tiene registro",
            TipoCuenta: tempo.TipoCuenta ?? "No tiene registro",
            ToalAPagar: tempo.ToalAPagar ?? "No tiene registro",
            retiroaalamano: tempo.retiroaalamano ?? "No tiene registro",
            retironequi: tempo.retironequi ?? "No tiene registro",
        }));

        const columns = [
            { field: 'CodigoPunto', headerName: 'Código de Punto' },
            { field: 'Mes', headerName: 'Mes' },
            { field: 'AhorroAlaMano', headerName: 'Ahorro a la Mano' },
            { field: 'Nit', headerName: 'NIT' },
            { field: 'Ajuste', headerName: 'Ajuste' },
            { field: 'AnioPago', headerName: 'Año de Pago' },
            { field: 'CambioClave', headerName: 'Cambio de Clave' },
            { field: 'Comsion', headerName: 'Comisión' },
            { field: 'ConsultaSaldo', headerName: 'Consulta de Saldo' },
            { field: 'CuentaPagocomision', headerName: 'Cuenta Pago Comisión' },
            { field: 'CupoAprobado', headerName: 'Cupo Aprobado' },
            { field: 'Fecha', headerName: 'Fecha' },
            { field: 'FechaPago', headerName: 'Fecha de Pago' },
            { field: 'Monetarias', headerName: 'Monetarias' },
            { field: 'No_monetarias', headerName: 'No Monetarias' },
            { field: 'NombreCodigo', headerName: 'Nombre Código' },
            { field: 'NombreGestor', headerName: 'Nombre Gestor' },
            { field: 'NombreVendedor', headerName: 'Nombre Vendedor' },
            { field: 'PagoPendiente', headerName: 'Pago Pendiente' },
            { field: 'RecargaNequi', headerName: 'Recarga Nequi' },
            { field: 'Recaudo', headerName: 'Recaudo' },
            { field: 'RecaudoCodensa', headerName: 'Recaudo Codensa' },
            { field: 'Retencion', headerName: 'Retención' },
            { field: 'TipoCuenta', headerName: 'Tipo de Cuenta' },
            { field: 'ToalAPagar', headerName: 'Total a Pagar' },
            { field: 'retiroaalamano', headerName: 'Retiro a la Mano' },
            { field: 'retironequi', headerName: 'Retiro Nequi' },
            { field: 'Sura', headerName: 'Sura' },
        ];

        return { columns, data };
    } catch (error) {
        console.error('Error al obtener los datos tableTemporal: ', error);
    }
}