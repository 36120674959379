import { isAuthenticated } from "./context/verificationUser";

import Rate from "./layouts/rates";
import Users from "./layouts/users";
import Temporal from "./layouts/temporal";
import Commission from "./layouts/commission";
import Authentication from "./layouts/authentication";
import DashboardGate from "./layouts/dashboard/generate";

let verify = isAuthenticated();

const authRequiredRoutes = [
    {
        route: "/dashboard",
        component: <DashboardGate />,
        noCollapse: false,
        requiredRoles: ["ADMIN", "CORRESPONSALES"],
        name: "Fact comisiones",
        key: "Dashboard",
    },
    {
        route: "/add",
        component: <Commission />,
        noCollapse: false,
        requiredRoles: ["ADMIN"],
        name: "Cargar comisión",
        key: "add",
    },
    {
        route: "/tarifas",
        component: <Rate />,
        noCollapse: false,
        requiredRoles: ["ADMIN"],
        name: "Tarifas",
        key: "tarifas",
    },
    {
        route: "/Users",
        component: <Users />,
        noCollapse: false,
        requiredRoles: ["ADMIN"],
        name: "Usuarios",
        key: "Users",
    }, 
    {
        route: "/temporal",
        component: <Temporal />,
        noCollapse: false,
        requiredRoles: ["ADMIN"],
        name: "Comisión temporal",
        key: "temporal",
    },
]

const authPublicRoutes = [
    {
        route: "/signIn",
        component: <Authentication />,
        noCollapse: false,
        name: "sign-up",
        key: "sign-up",
    },
]

const routes = verify ? authRequiredRoutes : authPublicRoutes;

export default routes;