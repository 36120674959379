import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MarginContent = ({ children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const pxToRem = (px) => `${px / 16}rem`;

    return (
        <Box
            sx={{
                p: 1,
                position: "relative",
                marginLeft: isMobile ? "0" : "auto",
                marginRight: isMobile ? "0" : pxToRem(20),
                mt: isMobile ? pxToRem(50) : "0",
                width: isMobile ? "95%" : "70%",
                height: 'auto',
                transition: theme.transitions.create(["margin-left", "margin-right", "width", "margin-top"], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                }),
            }}
        >
            {children}
        </Box>
    );
};

export default MarginContent;
