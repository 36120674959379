import Axios from "../../../../config/axios";
import { URL_LOGIN_SEND_CODE } from "../../../../constanst/link";

export const sendCode = async (username, setStep, setError, setLoading) => {
    try {
        let data = { usuario: username }
        const response = await Axios.post(URL_LOGIN_SEND_CODE, data);

        if (response.status === 200) {
            setStep(2); // Avanza al siguiente paso para ingresar el código de verificación
        } else {
            setError('Error al enviar el número de usuario. Por favor, inténtalo de nuevo.');
        }
    } catch (error) {
        console.error('Error al enviar el número de usuario:', error);
        setError(error.response?.data || 'Error al enviar el codigo. Por favor, inténtalo más tarde.');
    } finally {
        setLoading(false);
    }
};