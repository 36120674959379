import { ErrorGeneral } from "../../../components/Alerts";
import Axios from "../../../config/axios";
import { URL_COMMISSION_TEMPORAL_PROCESAR_VERIFY } from "../../../constanst/link";
import { isAuthenticated } from "../../../context/verificationUser";

export const processVerify = async () => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    try {
        const response = await Axios.get(URL_COMMISSION_TEMPORAL_PROCESAR_VERIFY);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error al verificacr el proceso:', error);
        ErrorGeneral('Vaya... tuvimos un problema al verificar proceso');
    }
} 
