import React, { useState } from 'react';
import { User } from '../data/triggers/postUser';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';

const LoginUser = () => {
    const [formData, setFormData] = useState({
        usuario: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            await User(formData, setError, setLoading);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Iniciar sesión
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: '400px' }}>
                <TextField
                    label="Usuario"
                    variant="outlined"
                    name="usuario"
                    value={formData.usuario}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Contraseña"
                    variant="outlined"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{ marginTop: 2, textTransform: 'initial',  backgroundColor: 'var(--color-principal)',}}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Iniciar'}
                </Button>   
                {error && (
                    <Typography variant="body2" sx={{ color: 'red', marginTop: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default LoginUser;
