import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, ListItemButton, Switch, CircularProgress } from '@mui/material';

// component
import routes from '../../routers';
import { hasRole, isAuthenticatedDispach } from '../../context/verificationUser';

// imgenes 
import logo from "../../assets/img/logo.png";

// iconos
import { CiLogin } from "react-icons/ci";
import { RiMenuFold4Line } from "react-icons/ri";
import { userVerify } from '../../utils/user/getUser';
import { wh } from './trigger/getWh';
import ModalLittle from '../Modal/modalLittle';

function Menu() {
    const [loading, setLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const location = useLocation();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setIsSwitchOn(isChecked);
        setOpenDialog2(true);
        setLoading(true);
    };

    const handleCloseDialog2 = () => {
        setOpenDialog2(false);
        setLoading(true);
    };

    const successWh = async () => {
        setIsSwitchOn(!isSwitchOn);
        handleCloseDialog2(false);
        setLoading(false);
        await wh();
    };

    // Verificar si el usuario es CORRESPONSALES
    const isCorrespondent = hasRole("CORRESPONSALES");
    const filteredRoutes = routes.filter(route => hasRole(route.requiredRoles));

    useEffect(() => {
        setLoading(true);
        if (isCorrespondent) {
            return;
        }
        const getTableData = async () => {
            const tableData = await userVerify();
            if (tableData) {
                setIsSwitchOn(tableData);
                setLoading(false);
            }
        };

        const intervalId = setInterval(() => {
            getTableData();
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <>
            {/* ------------------------------------------------------- MOVIL ------------------------------------------------------- */}

            <AppBar position="fixed" sx={{ display: { md: 'none' }, cursor: 'pointer' }} onClick={handleDrawerToggle}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <RiMenuFold4Line />
                    </IconButton>
                    <Typography variant="h6">Menu</Typography>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    width: 240,
                    flexShrink: 0,
                    borderRadius: 3,
                    [`& .MuiDrawer-paper`]: {
                        width: 250,
                        height: '95vh',
                        boxSizing: 'border-box',
                        borderRadius: 5,
                        boxShadow: '0px 0px 70px 40px rgba(0, 0, 0, 0.1)',
                        margin: 4,
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    },
                    display: { xs: 'block', md: 'none' }
                }}
            >
                <img src={logo} alt='logo' style={{ width: 140, marginLeft: 60, }} />
                <List sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', margin: 3 }}>
                    {filteredRoutes.map((route) => (
                        <ListItem
                            key={route.key}
                            disablePadding
                        >
                            <ListItemButton
                                component={Link}
                                to={route.route}
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 1,
                                    mb: 1,
                                    borderRadius: 3,
                                    boxShadow: location.pathname === route.route ? 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: '#d7ab02',
                                        color: 'black',
                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem'
                                    },
                                }}
                            >
                                <ListItemText primary={route.name} sx={{
                                    color: location.pathname === route.route ? 'var(--color-secundario)' : 'inherit',
                                    '&:hover': {
                                        color: 'black',
                                    }
                                }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {!isCorrespondent && (
                        <ListItem
                            disablePadding
                        >
                            <ListItemButton
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 1,
                                    mb: 1,
                                    borderRadius: 3,
                                }}
                            >
                                <ListItemText primary="WhatsApp" sx={{
                                    color: 'black',
                                    '&:hover': {
                                        color: 'black',
                                    }
                                }} />
                                {loading ? <CircularProgress size={24} color="inherit" /> : <Switch checked={isSwitchOn} onChange={handleSwitchChange} />}

                            </ListItemButton>
                        </ListItem>
                    )}
                    <ListItem
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 1,
                                mb: 1,
                                borderRadius: 3,
                            }}
                            onClick={() => { isAuthenticatedDispach(); }}
                        >
                            <ListItemText primary="Cerrar sesión" sx={{
                                color: 'black',
                                '&:hover': {
                                    color: 'black',
                                }
                            }} />
                            <CiLogin size={20} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            {/* ------------------------------------------------------- DESKTOP ------------------------------------------------------- */}

            <Drawer
                variant="permanent"
                sx={{
                    width: 260,
                    margin: 2,
                    flexShrink: 0,
                    borderRadius: 2,
                    [`& .MuiDrawer-paper`]: {
                        width: 250,
                        height: '95vh',
                        margin: 2,
                        border: 'none',
                        background: '#f8f9fa'
                    },
                    display: { xs: 'none', md: 'flex' }
                }}
                open
            >
                <img src={logo} alt='logo' style={{ width: 140, marginLeft: 47, }} />

                <List sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', margin: 3 }}>
                    {filteredRoutes.map((route) => (
                        <ListItem
                            key={route.key}
                            disablePadding
                        >
                            <ListItemButton
                                component={Link}
                                to={route.route}
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 1,
                                    mb: 1,
                                    borderRadius: 3,
                                    boxShadow: location.pathname === route.route ? 'rgba(0, 0, 0, 0.07) 0rem 1.25rem 1.6875rem 0rem' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: '#d7ab02',
                                        color: 'black',
                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem'
                                    },
                                }}
                            >
                                <ListItemText primary={route.name} sx={{
                                    color: location.pathname === route.route ? 'var(--color-secundario)' : 'inherit',
                                    '&:hover': {
                                        color: 'black',
                                    }
                                }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {!isCorrespondent && (
                        <ListItem
                            disablePadding
                        >
                            <ListItemButton
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 1,
                                    mb: 1,
                                    borderRadius: 3,
                                    '&:hover': {
                                        backgroundColor: '#d7ab02',
                                        color: 'black',
                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem'
                                    },
                                }}
                            >
                                <ListItemText primary="WhatsApp" sx={{ color: 'black' }} />
                                {loading ? <CircularProgress size={24} color="inherit" /> : <Switch checked={isSwitchOn} onChange={handleSwitchChange} />}

                            </ListItemButton>
                        </ListItem>
                    )}
                    <ListItem
                        disablePadding
                    >
                        <ListItemButton
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 1,
                                mb: 1,
                                borderRadius: 3,
                                '&:hover': {
                                    backgroundColor: '#d7ab02',
                                    color: 'black',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem'
                                },
                            }}
                            onClick={() => { isAuthenticatedDispach(); }}
                        >
                            <ListItemText primary="Cerrar sesión" sx={{ color: 'black' }} />
                            <CiLogin size={20} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            <ModalLittle
                openDialog={openDialog2}
                handleCloseDialog={handleCloseDialog2}
                succesClick={successWh}
                dialogTitle="Confirmación"
                dialogContent="¿Estás seguro de querer realizar esta acción? Se realizará un cambio a nivel global en todos los usuarios que tenga mensejeria por WhatsApp"
            />
        </>
    );
}

export default Menu;