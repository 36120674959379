import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

//components
import ModalBig from '../../../components/Modal/modalBig';
import { ErrorGeneral, Infogeneral } from '../../../components/Alerts';
import { putUsers } from '../data/triggers/putUser';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
};

function PutUser({ dataUser, idUser, handleRefresh, openDialog, handleCloseDialog }) {

    const [formValues, setFormValues] = useState({
        username: '',
        email: '',
        fullname: '',
        groupid: '',
        password: '',
        numero_celular: ''
    });
    const [errors, setErrors] = useState({});

    const deleteValues = async (e) => {
        setFormValues({
            username: '',
            email: '',
            fullname: '',
            groupid: '',
            password: '',
            numero_celular: ''
        })
    }

    const validate = () => {
        const newErrors = {};

        if (!formValues.email.trim() &&
            !formValues.fullname.trim() &&
            !formValues.groupid.trim() &&
            !formValues.password.trim() &&
            !formValues.username.trim() &&
            !formValues.numero_celular.trim()) {
            return false;
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!validate()) {
            Infogeneral('Debes rellenar al menos uno de los campos');
            deleteValues();
            handleCloseDialog();
            return;
        }

        handleCloseDialog();

        try {
            await putUsers(idUser, formValues, handleRefresh);
            handleRefresh();
            deleteValues();
        } catch (error) {
            console.error('Error en handleSubmit:', error);
            ErrorGeneral('Vaya... tuvimos un problema en el botón');
        }
    };

    return (
        <ModalBig openDialog={openDialog} handleCloseDialog={handleCloseDialog}>
            <Box sx={style} onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Actualiza usuario {idUser}
                </Typography>
                <form >
                    <TextField
                        label="Nombre de Usuario"
                        name="username"
                        value={formValues.username}
                        placeholder={dataUser.username || "No se tiene registro"}
                        onChange={handleChange}
                        fullWidth
                        isRequired
                        error={!!errors.username}
                        margin="normal"
                    />
                    <TextField
                        label="Correo Electrónico"
                        name="email"
                        value={formValues.email}
                        placeholder={dataUser.email || "No se tiene registro"}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.email}
                        margin="normal"
                    />
                    <TextField
                        label="Número de teléfono"
                        name="numero_celular"
                        value={formValues.numero_celular}
                        placeholder={dataUser.numero_celular || "No se tiene registro"}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.numero_celular}
                        margin="normal"
                    />
                    <TextField
                        label="Nombre Completo"
                        name="fullname"
                        value={formValues.fullname}
                        placeholder={dataUser.fullname || "No se tiene registro"}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.fullname}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal" error={!!errors.groupid}>
                        <InputLabel htmlFor="groupid">ID del Grupo</InputLabel>
                        <Select
                            id="groupid"
                            name="groupid"
                            value={formValues.groupid}
                            onChange={handleChange}
                        >
                            <MenuItem value="" disabled><em>{dataUser.groupid || "No se tiene registro"}</em></MenuItem>
                            <MenuItem value="CORRESPONSALES">CORRESPONSALES</MenuItem>
                            <MenuItem value="ADMIN">ADMIN</MenuItem>
                        </Select>
                        {errors.groupid && <Typography variant="caption" color="error">{errors.groupid}</Typography>}
                    </FormControl>
                    <TextField
                        label="Contraseña"
                        name="password"
                        type="password"
                        value={formValues.password}
                        placeholder={dataUser.password || "No se tiene registro"}
                        onChange={handleChange}
                        isRequired
                        fullWidth
                        error={!!errors.password}
                        margin="normal"
                    />
                    <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: 'initial', backgroundColor: 'var(--color-principal)', borderRadius: 3 }}
                        >
                            Actualizar
                        </Button>
                        <Button
                            onClick={() => { handleCloseDialog(); deleteValues(); }}
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: 'initial', backgroundColor: 'red', borderRadius: 3 }}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </form>
            </Box>
        </ModalBig>
    );
}

PutUser.propTypes = {
    idUser: PropTypes.number,
    dataUser: PropTypes.string,
    setLoading: PropTypes.func.isRequired,
    openDialog: PropTypes.bool.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default PutUser;