import React from 'react';

function PageFooter() {

    return (
        <footer style={{ bottom: 0, position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
            <p style={{ fontSize: 17 }}>
                {"Todos los derechos reservados a "}
                <a href="https://integroanalytics.my.canva.site/bitmeta" target='blank'>
                    BitSoluciones
                </a>{" © "}
                {new Date().getFullYear()}
            </p>
        </footer>
    );
}

export default PageFooter;