import Cookies from 'js-cookie';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { URL_SECRET } from '../constanst/link';

export const getDecryptedUserObject = () => {
    const encryptedUserObject = Cookies.get('object');
    if (!encryptedUserObject) return null;

    try {
        const bytes = AES.decrypt(encryptedUserObject, URL_SECRET);
        const decryptedData = bytes.toString(Utf8);

        return JSON.parse(decryptedData);
    } catch (e) {
        console.error('Error al optener la cookie', e);
        return null;
    }
};


export const getDecryptedPayloadObject = () => {
    const encryptedUserObject = Cookies.get('payload');
    if (!encryptedUserObject) return null;

    try {
        const bytes = AES.decrypt(encryptedUserObject, URL_SECRET);
        const decryptedData = bytes.toString(Utf8);

        return decryptedData;
    } catch (e) {
        console.error('Error al optener la cookie', e);
        return null;
    }
};