export const URL_BASE = process.env.REACT_APP_API_URL;
export const URL_KEY = process.env.REACT_APP_API_KEY;
export const URL_SECRET = process.env.REACT_APP_KEY;

//#region AUTENTIFICATION
export const URL_LOGIN_USER = 'login';
export const URL_LOGIN_SEND_CODE = 'sendCode';
export const URL_LOGIN_VERIFICATION_CODE = 'loginWh';

//#region COMISION
export const URL_COMMISSION_GENERAL = 'factComisionAdmin';
export const URL_COMMISSION_PDF = 'pdfComision';
export const URL_COMMISSION_WH = 'envioComisionWh';

//#region COMISION TEMPORAL
export const URL_COMMISSION = 'formatoComision';
export const URL_COMMISSION_ADD = 'cargarComision';
export const URL_COMMISSION_TEMPORAL = 'comisionTemporal';
export const URL_COMMISSION_TEMPORAL_PROCESAR = 'activador';
export const URL_COMMISSION_TEMPORAL_PROCESAR_VERIFY = 'statusComision';

//#region TARIFAS
export const URL_TARIFAS = 'tarifas';
export const URL_TARIFAS_ADD = 'cargartarifas';
export const URL_TARIFAS_EXPORT = 'exportarTarifas';

//#region WH AND USERS
export const URL_USER = 'users';
export const URL_ACTIVE_WH = 'setearWh';
export const URL_USER_PUT = 'editarUsuario';
export const URL_USER_ADD = 'cargarUsuario';

