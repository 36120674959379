import Axios from "../../../config/axios";
import { getDecryptedUserObject } from "../../../context/aunth";
import { URL_COMMISSION_GENERAL } from "../../../constanst/link";
import { hasRole, isAuthenticated } from "../../../context/verificationUser";

export const facts = async (mes, anio, text, page, setTotalRows) => {
    let pageSize = 50;

    const verify = isAuthenticated();
    const user = getDecryptedUserObject();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    let queryParams = `?pageSize=${pageSize}&page=${page}&mes=${mes}&anio=${anio}`;

    const isCorrespondent = hasRole("CORRESPONSALES");
    
    if (isCorrespondent) {
        queryParams += `&CodigoPunto=${user.username}`;
    } else if (text != null && text.trim() !== '') {
        queryParams += `&CodigoPunto=${text}`;
    }

    try {
        const response = await Axios.get(`${URL_COMMISSION_GENERAL}${queryParams}`);

        if (response.status === 200) {
            setTotalRows(response.data.total);
            return response.data.Founded;
        }
    } catch (error) {
        console.error('Error al cargar:', error);
        return [];
    }
};