import { Checkgeneral, ErrorGeneral, Infogeneral } from "../../../../components/Alerts";
import Axios from "../../../../config/axios";
import { URL_USER_PUT } from "../../../../constanst/link";
import { isAuthenticated } from "../../../../context/verificationUser";

export const putUsers = async (idUser, formValues, handleRefresh) => {
    const verify = isAuthenticated();
    if (!verify) {
        console.error("User is not authenticated");
        return;
    }

    if (!formValues || formValues.length === 0) {
        console.error("No file provided");
        Infogeneral('No se proporcionó datos para actualizar.');
        return;
    }

    try {
        const response = await Axios.put(`${URL_USER_PUT}?ID=${idUser}`, formValues);

        if (response.status === 200) {
            Checkgeneral('Se ha actualizado el usuario');
            setTimeout(function () {
                handleRefresh();
            }, 1000);
        } else {
            ErrorGeneral('La respuesta del servidor no es 200.');
        }

    } catch (error) {
        console.error('Error al subir:', error);
        ErrorGeneral('Vaya... tuvimos un problema al actualizar.');
    }
};