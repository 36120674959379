import routes from './routers';
import getRoutes from './route';
import { isAuthenticated } from './context/verificationUser';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  let verify = isAuthenticated();
  
  return (
    <Router>
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={verify ? <Navigate to="/dashboard" /> : <Navigate to="/signIn" />} />
      </Routes>
    </Router>
  );
}

export default App;